import AccordeonComponent from "../../../../../components/accordeon/AccordeonComponent";
import { useState, useEffect } from "react";
import { List } from "./list";
import { ItemList } from "./itemList";
import { Alert } from "../../../../../assets/alert";
import { Arrow } from "../../../../../assets/arrow";
import { Link } from "react-router-dom";
import { routes } from "../../../../../routes/RoutesComponent";

export const FeatureList = ({ car, description }) => {
    const [claimsInfo, setClaimsInfo] = useState(false);
    const [warrantyInfo, setWarrantyInfo] = useState(false);

    const traction = [
        { id: 0, text: "Trasera" },
        { id: 1, text: "4 x 4" },
        { id: 2, text: "Deltantera" },
        { id: 3, text: "4 x 2" },
    ];

    const warrantyMessage = () => {
        if (!car.warranty_type || car.warranty_type === "null") return "Garantía de 6 meses";

        switch (car.warranty_type) {
            case "Garantía de 6 meses o 4000 km":
                return "Garantía de 6 meses";
            case "Garantía de marca o km":
                return "Garantía de fábrica";
            default:
                return "Garantía de 6 meses";
        }
    };

    return (
        <div className="w-100">
            <AccordeonComponent
                options={[
                    {
                        title: "Garantía",
                        // showOption: car.warranty,
                        content: (
                            <div className="d-flex flex-column flex-md-row">
                                <List>
                                    <ItemList
                                        onClick={() => setWarrantyInfo(!warrantyInfo)}
                                        icon={"/assets/icons/featureList/2.png"}
                                        title={"Garantía"}
                                        content={
                                            <>
                                                <div className="position-relative">
                                                    <div
                                                        style={{
                                                            display: "inline-flex",
                                                            alignItems: "center",
                                                            marginTop: "0",
                                                        }}
                                                    >
                                                        <Alert />

                                                        <strong
                                                            style={{ margin: "0 2px 0 0", height: "18px" }}
                                                            className="ms-2"
                                                        >
                                                            {car.warranty ||
                                                            (car.warranty_type != "null" && car.warranty_type) ||
                                                            car.garantia7dias
                                                                ? "SÍ"
                                                                : "NO"}
                                                        </strong>

                                                        {/* <div
                                                            className="ms-2"
                                                            style={{
                                                                transform: warrantyInfo
                                                                    ? "rotate(0deg)"
                                                                    : "rotate(180deg)",
                                                            }}
                                                        >
                                                            <Arrow />
                                                        </div> */}
                                                    </div>
                                                    {/* <span
                                                        className="warranty_tool_tip_detail position-absolute"
                                                        style={{ display: warrantyInfo ? "block" : "none" }}
                                                    >
                                                        {car.warranty
                                                            ? "Te ofrecemos una garantía de 6 meses o 4.000 km por defectos de fabricación en más de 3,000 componentes del auto.  No cubrimos piezas de desgaste ni daños ocasionados por mal manejo o falta de mantenimiento. Para conocer el detalle ponte en contacto con un consejero."
                                                            : "La garantía aplica para autos con menos de 10 años de antigüedad y que en la publicación se diga explícitamente que cuentan con garantía. Para mayor información contáctese con un consultor."}
                                                    </span> 
                                                    
                                                    */}
                                                </div>
                                            </>
                                        }
                                    />
                                    {(car.warranty || car.warranty_type) && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/2.png"}
                                            title={"Tipo de garantía"}
                                            content={
                                                <p>
                                                    {warrantyMessage()}
                                                    <Link
                                                        style={{ color: "#0000ff" }}
                                                        target="_blank"
                                                        to={"/Terminos-y-condiciones-garantia-vehiculos-usados/25"}
                                                    >
                                                        {" "}
                                                        Términos y condiciones acá.
                                                    </Link>
                                                </p>
                                            }
                                        />
                                    )}
                                </List>
                                <List>
                                    {description?.find((item) => item?.item === 40)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/2.png"}
                                            title={"Garantía de fábrica"}
                                            content={description?.find((item) => item?.item === 40)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 41)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/2.png"}
                                            title={"Garantía de kilometraje"}
                                            content={description?.find((item) => item?.item === 41)?.content}
                                        />
                                    )}
                                </List>
                            </div>
                        ),
                    },
                    {
                        title: "Historial, documentación y llaves",
                        content: (
                            <div
                                className="d-flex flex-column flex-md-row"
                                onClick={() => claimsInfo && setClaimsInfo(false)}
                            >
                                <List>
                                    {description?.find((item) => item?.item === 42)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/car-key.png"}
                                            title={"Número de llaves"}
                                            content={description?.find((item) => item?.item === 42)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 4)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/5.png"}
                                            title={"Fecha de vencimiento SOAT"}
                                            content={description?.find((item) => item?.item === 4)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 5)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/5.png"}
                                            title={"Fecha de vencimiento revisión tecnicomecánica"}
                                            content={description?.find((item) => item?.item === 5)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 3)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/5.png"}
                                            title={"Ciudad de matrícula"}
                                            content={description?.find((item) => item?.item === 3)?.content}
                                        />
                                    )}
                                </List>
                                <List>
                                    <ItemList
                                        icon={"/assets/icons/featureList/5.png"}
                                        title={"Placa"}
                                        content={car.plate}
                                    />
                                    <ItemList
                                        icon={"/assets/icons/featureList/3.png"}
                                        title={"Reclamaciones reportadas y tipo"}
                                        content={
                                            <div className="position-relative">
                                                <div
                                                    className="d-flex align-items-top"
                                                    onClick={() => setClaimsInfo(!claimsInfo)}
                                                >
                                                    <Alert height={18} width={18} />
                                                    <p className="d-flex align-items-center mx-1">
                                                        {description?.find((item) => item?.item === 2)?.content ?? "NO"}
                                                    </p>
                                                    {/* {car.types_claims && ( */}
                                                    <div
                                                        className="align-self-center"
                                                        style={{
                                                            transform: claimsInfo ? "rotate(180deg)" : "rotate(0deg)",
                                                        }}
                                                    >
                                                        <Arrow />
                                                    </div>
                                                    {/* )} */}
                                                </div>
                                                {/* {car.types_claims && ( */}
                                                <span
                                                    className="claims position-absolute"
                                                    style={{
                                                        display: claimsInfo ? "block" : "none",
                                                        left: -35,
                                                    }}
                                                >
                                                    <div>
                                                        <p style={{ fontWeight: "normal", fontSize: "16px" }}>
                                                            <strong>Reclamaciones</strong>En nuestro compromiso con la
                                                            transparencia, proporcionamos detalles sobre las
                                                            reclamaciones o accidentes reportados por las aseguradoras.
                                                            <br />
                                                            <br />
                                                            <strong>
                                                                ¿Qué es una reclamación de menor cuantía o pérdida
                                                                parcial?
                                                            </strong>{" "}
                                                            Una reclamación de menor cuantía ocurre cuando el costo de
                                                            reparar o reemplazar partes del vehículo no excede el 75%
                                                            del valor asegurado.
                                                            <br />
                                                            <br />
                                                            <strong>¿Qué es una pérdida total?</strong> Una pérdida
                                                            total se produce cuando las reparaciones de los daños de un
                                                            vehículo superan el 75% de su valor asegurable.
                                                            <br />
                                                            <br />
                                                            <strong>¿Qué es un auto recuperado?</strong> Un auto
                                                            recuperado es aquel que previamente estaba asegurado, fue
                                                            robado y el propietario recibió una indemnización por parte
                                                            de la aseguradora. Sin embargo, posteriormente el vehículo
                                                            fue recuperado por la aseguradora, lo que resulta en un
                                                            reporte de recuperación asociado al automóvil. Este evento
                                                            puede afectar el valor comercial del vehículo.
                                                        </p>
                                                    </div>
                                                </span>
                                                {/* )} */}
                                            </div>
                                        }
                                    />
                                    {description?.find((item) => item?.item === 6)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/shield.svg"}
                                            title={"Monto de reclamaciones"}
                                            content={description?.find((item) => item?.item === 6)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 1)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/5.png"}
                                            title={"Número de propietarios anteriores"}
                                            content={description?.find((item) => item?.item === 1)?.content}
                                        />
                                    )}
                                </List>
                            </div>
                        ),
                    },
                    {
                        title: "Características",
                        content: (
                            <div className="d-flex flex-column flex-md-row">
                                <List>
                                    <ItemList
                                        icon={"/assets/icons/featureList/6.png"}
                                        title={"Cilindraje"}
                                        content={car.motor}
                                    />
                                    <ItemList
                                        icon={"/assets/icons/featureList/7.png"}
                                        title={"Tipo de combustible"}
                                        content={car.typeOfFuel.name}
                                    />
                                    <ItemList
                                        icon={"/assets/icons/featureList/8.png"}
                                        title={"Tipo de caja"}
                                        content={car.transmission}
                                    />
                                    <ItemList
                                        icon={"/assets/icons/featureList/9.png"}
                                        title={"Tipo de vehículo"}
                                        content={car.type.name}
                                    />
                                </List>
                                <List>
                                    {description?.find((item) => item?.item === 8)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/10.png"}
                                            title={"Número de puestos"}
                                            content={description?.find((item) => item?.item === 8)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 7)?.content && (
                                        <ul className="d-flex flex-column flex-md-row m-0 list-unstyled w-100">
                                            <ItemList
                                                icon={"/assets/icons/featureList/12.png"}
                                                title={"Turbo"}
                                                content={description?.find((item) => item?.item === 7)?.content}
                                            />
                                        </ul>
                                    )}
                                    <ItemList
                                        icon={"/assets/icons/featureList/11.png"}
                                        title={"Tracción"}
                                        content={traction?.find((item) => item?.id === car.traction)?.text}
                                    />
                                </List>
                            </div>
                        ),
                    },
                    {
                        title: "Seguridad",
                        showOption: description?.find((list) => list?.list === 3) ? true : false,
                        content: (
                            <div className="d-flex flex-column flex-md-row">
                                <List>
                                    {description?.find((item) => item?.item === 9)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/13.png"}
                                            title={"Airbags"}
                                            content={description?.find((item) => item?.item === 9)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 10)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/14.png"}
                                            title={"Frenos ABS"}
                                            content={description?.find((item) => item?.item === 10)?.content}
                                        />
                                    )}
                                </List>
                                <List>
                                    {description?.find((item) => item?.item === 11)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/15.png"}
                                            title={"Calificación seguridad"}
                                            content={description?.find((item) => item?.item === 11)?.content}
                                        />
                                    )}
                                </List>
                            </div>
                        ),
                    },
                    {
                        title: "Accesorios y  equipamiento",
                        showOption: description?.find((list) => list?.list === 4) ? true : false,
                        content: (
                            <div className="d-flex flex-column flex-md-row">
                                <List>
                                    {description?.find((item) => item?.item === 12)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/16.png"}
                                            title={"Sillas"}
                                            content={description?.find((item) => item?.item === 12)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 13)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/5.png"}
                                            title={"Tipo de radio"}
                                            content={description?.find((item) => item?.item === 13)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 14)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/17.png"}
                                            title={"Entretenimiento"}
                                            content={description?.find((item) => item?.item === 14)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 15)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/18.png"}
                                            title={"Sunroof"}
                                            content={description?.find((item) => item?.item === 15)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 16)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/19.png"}
                                            title={"Rines de lujo"}
                                            content={description?.find((item) => item?.item === 16)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 17)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/20.png"}
                                            title={"Sensores de reversa"}
                                            content={description?.find((item) => item?.item === 17)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 18)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/21.png"}
                                            title={"Cámara trasera"}
                                            content={description?.find((item) => item?.item === 18)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 19)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/22.png"}
                                            title={"Cámara 360°"}
                                            content={description?.find((item) => item?.item === 19)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 20)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/23.png"}
                                            title={"Alarma"}
                                            content={description?.find((item) => item?.item === 20)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 21)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/24.png"}
                                            title={"Asistente anti colisión"}
                                            content={description?.find((item) => item?.item === 21)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 22)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/25.png"}
                                            title={"Exploradoras"}
                                            content={description?.find((item) => item?.item === 22)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 23)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/26.png"}
                                            title={"Bluetooth"}
                                            content={description?.find((item) => item?.item === 23)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 24)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/27.png"}
                                            title={"USB"}
                                            content={description?.find((item) => item?.item === 22)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 25)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/28.png"}
                                            title={"Sensor de lluvia"}
                                            content={description?.find((item) => item?.item === 23)?.content}
                                        />
                                    )}
                                </List>
                                <List>
                                    {description?.find((item) => item?.item === 26)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/29.png"}
                                            title={"Sensor de luz"}
                                            content={description?.find((item) => item?.item === 24)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 27)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/5.png"}
                                            title={"Vidrios eléctricos"}
                                            content={description?.find((item) => item?.item === 27)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 28)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/31.png"}
                                            title={"Espejos eléctricos"}
                                            content={description?.find((item) => item?.item === 28)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 29)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/32.png"}
                                            title={"Sillas eléctricas"}
                                            content={description?.find((item) => item?.item === 29)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 30)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/33.png"}
                                            title={"Llanta de repuesto"}
                                            content={description?.find((item) => item?.item === 30)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 31)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/34.png"}
                                            title={"Apertura automática de baúl"}
                                            content={description?.find((item) => item?.item === 31)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 32)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/35.png"}
                                            title={"Calefacción en las sillas"}
                                            content={description?.find((item) => item?.item === 32)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 33)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/36.png"}
                                            title={"Timón ajustable"}
                                            content={description?.find((item) => item?.item === 33)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 34)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/37.png"}
                                            title={"Pedales ajustables"}
                                            content={description?.find((item) => item?.item === 34)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 35)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/37.png"}
                                            title={"Aire acondicionado"}
                                            content={description?.find((item) => item?.item === 35)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 36)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/37.png"}
                                            title={"Tipo de dirección"}
                                            content={description?.find((item) => item?.item === 36)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 37)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/37.png"}
                                            title={"Comandos en el timón"}
                                            content={description?.find((item) => item?.item === 37)?.content}
                                        />
                                    )}
                                    {description?.find((item) => item?.item === 38)?.content && (
                                        <ItemList
                                            icon={"/assets/icons/featureList/37.png"}
                                            title={"Kit de carretera"}
                                            content={description?.find((item) => item?.item === 38)?.content}
                                        />
                                    )}
                                </List>
                            </div>
                        ),
                    },
                    {
                        title: "Comentarios adicionales",
                        showOption: description?.find((list) => list?.list === 5) ? true : false,
                        content: description?.find((item) => item?.item === 39)?.content,
                    },
                ]}
            />
        </div>
    );
};
